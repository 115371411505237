.pending{
    background-color: #ffc107;
    padding: 2px 12px;
    border-radius: 10px;
    font-size: 14px;
}

.accepted{
    background-color: rgb(22, 207, 150);
    padding: 2px 12px;
    border-radius: 10px;
    font-size: 14px;
}