.MyTextarea {
	width: 100%;
	padding: 5px 15px;
	height: 200px;
	overflow-y: auto;
	resize: none;
	border: 1px solid #31353e;
}

.MyTextarea:focus {
	border: 1px solid #31353e !important;
	box-shadow: 0 0 2px 1px #31353e;
}
