.form{
    width: 300px;
}

.label{
    width: 100%;
}

.email-input{
    border-radius: 5px;
    margin-top: 12px;

}

.cancel-button {
	padding: 5px 15px;
	color: #31353e;
	font-size: 14px;
	background: transparent;
	border: 1px solid #31353e;
	background: transparent;
	cursor: pointer;
	transition: all 0.3s ease 0s;
	border-radius: 5px;
}

.cancel-button:hover {
	color: #31353e96;
    border: 1px solid #31353e96;;
}

.error-message{
    color: red;
    font-size: 12px;
    padding-left: 4px;
	line-height: unset;
	margin-top:5px;
}