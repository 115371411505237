.loaderBody {
	position: fixed;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
/* .loader{
    position: absolute;
	width: 6em;
	height: 6em;
	border: 1.1em solid #8caad6;
	border-left: 1.1em solid #084aad;
	border-radius: 50%;
	animation: load8 1.1s infinite linear;
}
  
  @keyframes load8 {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  } */

.loader {
	width: 8vmax;
	height: 8vmax;
	border-right: 4px solid #1e1e1e;
	border-radius: 100%;
	-webkit-animation: spinRight 800ms linear infinite;
	animation: spinRight 800ms linear infinite;
}
.loader:before,
.loader:after {
	content: "";
	width: 6vmax;
	height: 6vmax;
	display: block;
	position: absolute;
	top: calc(50% - 3vmax);
	left: calc(50% - 3vmax);
	border-left: 3px solid #1e1e1e;
	border-radius: 100%;
	-webkit-animation: spinLeft 800ms linear infinite;
	animation: spinLeft 800ms linear infinite;
}
.loader:after {
	width: 4vmax;
	height: 4vmax;
	top: calc(50% - 2vmax);
	left: calc(50% - 2vmax);
	border: 0;
	border-right: 2px solid #1e1e1e;
	-webkit-animation: none;
	animation: none;
}

@-webkit-keyframes spinLeft {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(720deg);
	}
}

@keyframes spinLeft {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(720deg);
	}
}
@-webkit-keyframes spinRight {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}
@keyframes spinRight {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}
