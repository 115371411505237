.myModal {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: none;
	background: rgba(0, 0, 0, 0.2);
	z-index: 100;
}

.myModalContent {
	padding: 25px;
	background: white;
	border-radius: 10px;
	min-width: 250px;
}

.myModal.active {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 678px) {
	.myModalContent {
		padding: 25px 10px;
	}
}
