.myBtn {
	padding: 5px 15px;
	color: #31353e;
	font-size: 14px;
	background: transparent;
	border: 1px solid #31353e;
	background: transparent;
	cursor: pointer;
	transition: all 0.3s ease 0s;
	border-radius: 5px;
}
.myBtn:hover {
	background-color: #31353e;
	color: white;
}
