.main-container {
    display: flex;
    padding: 12px 15px;
    outline: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 12px;
    justify-content: space-between;
    align-items: center;
    column-gap: 24px;
}

.info-container{
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.profile-block{
    display: flex;
    column-gap: 8px;
}

.info-label{
    display: flex;
    flex-direction: column;
    align-self: center;
}

.email {
    font-size: 12px;
}

.fullname{
    color: rgb(22, 207, 150)
}