/* .loader {
	animation: rotate 1s infinite;
	height: 50px;
	width: 50px;
}

.loader:before,
.loader:after {
	border-radius: 50%;
	content: "";
	display: block;
	height: 20px;
	width: 20px;
}
.loader:before {
	animation: ball1 1s infinite;
	background-color: #cb2025;
	box-shadow: 30px 0 0 #f8b334;
	margin-bottom: 10px;
}
.loader:after {
	animation: ball2 1s infinite;
	background-color: #00a096;
	box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg) scale(0.8);
		-moz-transform: rotate(0deg) scale(0.8);
	}
	50% {
		-webkit-transform: rotate(360deg) scale(1.2);
		-moz-transform: rotate(360deg) scale(1.2);
	}
	100% {
		-webkit-transform: rotate(720deg) scale(0.8);
		-moz-transform: rotate(720deg) scale(0.8);
	}
}

@keyframes ball1 {
	0% {
		box-shadow: 30px 0 0 #f8b334;
	}
	50% {
		box-shadow: 0 0 0 #f8b334;
		margin-bottom: 0;
		-webkit-transform: translate(15px, 15px);
		-moz-transform: translate(15px, 15px);
	}
	100% {
		box-shadow: 30px 0 0 #f8b334;
		margin-bottom: 10px;
	}
}

@keyframes ball2 {
	0% {
		box-shadow: 30px 0 0 #97bf0d;
	}
	50% {
		box-shadow: 0 0 0 #97bf0d;
		margin-top: -20px;
		-webkit-transform: translate(15px, 15px);
		-moz-transform: translate(15px, 15px);
	}
	100% {
		box-shadow: 30px 0 0 #97bf0d;
		margin-top: 0;
	}
} */

/* .loader {
	position: relative;
	width: auto;
	height: auto;
}

.loader div {
	height: 10px;
	width: 10px;
	background-color: #23adad;
	border-radius: 50%;
	position: absolute;
	-webkit-animation: opaque 0.8s ease-in-out infinite both;
	animation: opaque 0.8s ease-in-out infinite both;
}

.loader > div:nth-child(1) {
	top: -25px;
	left: 0;
}
.loader > div:nth-child(2) {
	top: -17px;
	left: 17px;
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}
.loader > div:nth-child(3) {
	top: 0;
	left: 25px;
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.loader > div:nth-child(4) {
	top: 17px;
	left: 17px;
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.loader > div:nth-child(5) {
	top: 25px;
	left: 0;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}
.loader > div:nth-child(6) {
	top: 17px;
	left: -17px;
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.loader > div:nth-child(7) {
	top: 0;
	left: -25px;
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}
.loader > div:nth-child(8) {
	top: -17px;
	left: -17px;
	-webkit-animation-delay: 0.7s;
	animation-delay: 0.7s;
}

@-webkit-keyframes opaque {
	0% {
		opacity: 0.1;
	}
	40% {
		opacity: 1;
	}
	80% {
		opacity: 0.1;
	}
	100% {
		opacity: 0.1;
	}
}

@keyframes opaque {
	0% {
		opacity: 0.1;
	}
	40% {
		opacity: 1;
	}
	80% {
		opacity: 0.1;
	}
	100% {
		opacity: 0.1;
	}
} */

/* .loader{
    position: absolute;
	width: 6em;
	height: 6em;
	border: 1.1em solid #8caad6;
	border-left: 1.1em solid #084aad;
	border-radius: 50%;
	animation: load8 1.1s infinite linear;
}
  
  @keyframes load8 {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  } */

.loader {
	width: 43px;
	height: 43px;
	display: inline-block;
	border-right: 3px solid #1e1e1e;
	border-radius: 100%;
	-webkit-animation: spinRight 800ms linear infinite;
	animation: spinRight 800ms linear infinite;
}
.loader:before,
.loader:after {
	content: "";
	width: 33px;
	height: 33px;
	display: block;
	position: absolute;
	top: calc(50% - 16.5px);
	left: calc(50% - 16.5px);
	border-left: 2px solid #1e1e1e;
	border-radius: 100%;
	-webkit-animation: spinLeft 800ms linear infinite;
	animation: spinLeft 800ms linear infinite;
}
.loader:after {
	width: 13px;
	height: 13px;
	top: calc(50% - 6.5px);
	left: calc(50% - 6.5px);
	border: 0;
	border-right: 1px solid #1e1e1e;
	-webkit-animation: none;
	animation: none;
}

@-webkit-keyframes spinLeft {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(720deg);
	}
}

@keyframes spinLeft {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(720deg);
	}
}
@-webkit-keyframes spinRight {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}
@keyframes spinRight {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}
